import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import './Analysis.css';
import logo from './logo.png';
import preview from './preview.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';

function Analysis() {
  const [data, setData] = useState(null);
  const [selfieUrl, setSelfieUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [presetColors, setPresetColors] = useState([]);
  const containerRef = useRef(null);

  const LIGHT_SPRING = [
      "#ee6879",
      "#f5ad93",
      "#fff1a5",
      "#61cb65",
      "#c7f1b7",
      "#96d6d2",
      "#9fe7f5",
      "#96c8fc",
  ]

  const BRIGHT_SPRING = [
      "#ed4847",
      "#f3a6a4",
      "#f5bedb",
      "#f7b640",
      "#fee83c",
      "#65d34e",
      "#5fd5c5",
      "#304098"
  ]

  const WARM_SPRING = [
      "#489a46",
      "#fd836a",
      "#bb4845",
      "#f7b640",
      "#ee6e47",
      "#e27f4d",
      "#84d46b",
      "#52b8ac",
  ]

  const LIGHT_SUMMER = [
      "#a36872",
      "#dbcef7",
      "#b5a1ce",
      "#f9dcf4",
      "#89e2b4",
      "#90b7fb",
      "#b5f4fb",
      "#4976bf",
  ]

  const COOL_SUMMER = [
      "#7c4171",
      "#bd8ec4",
      "#973055",
      "#d13e4d",
      "#183779",
      "#91c4ea",
      "#65e4dd",
      "#4ba685",
  ]

  const SOFT_SUMMER = [
      "#a06b94",
      "#c5787f",
      "#e5699b",
      "#828fed",
      "#fff6b7",
      "#4fad83",
      "#6f94b6",
      "#9cccc7",
  ]

  const SOFT_AUTUMN = [
      "#a7552e",
      "#e8713a",
      "#ecd761",
      "#ddc1b6",
      "#df867f",
      "#326493",
      "#6cbebe",
      "#9579a7",
  ]

  const WARM_AUTUMN = [
      "#9c2d35",
      "#c83a25",
      "#ee6030",
      "#e8c12e",
      "#844221",
      "#194234",
      "#636d0d",
      "#80b45d",
  ]

  const DEEP_AUTUMN = [
      "#be3b38",
      "#f49931",
      "#e4432d",
      "#fcd232",
      "#5f2d16",
      "#164a74",
      "#1a3e01",
      "#3e1a3a",
  ]

  const CLEAR_WINTER = [
      "#622b86",
      "#c53c5a",
      "#ec5095",
      "#e9e0fd",
      "#faf736",
      "#57b867",
      "#2b85c4",
      "#6befdc",
  ]

  const COOL_WINTER = [
      "#872b5a",
      "#aa9cbd",
      "#6f4786",
      "#252b5e",
      "#1a57be",
      "#e1f5fe",
      "#1f565e",
      "#3293cd",
  ]

  const DEEP_WINTER = [
      "#5d2f79",
      "#973055",
      "#324261",
      "#cd4486",
      "#fff264",
      "#1a2682",
      "#c73d57",
      "#1a4850",
  ]

  const SEASON_HEX_CODES = {
    LIGHT_SPRING,
    BRIGHT_SPRING,
    WARM_SPRING,
    LIGHT_SUMMER,
    COOL_SUMMER,
    SOFT_SUMMER,
    SOFT_AUTUMN,
    WARM_AUTUMN,
    DEEP_AUTUMN,
    CLEAR_WINTER,
    COOL_WINTER,
    DEEP_WINTER
  };

  const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

  const isSmallScreen = () => window.innerWidth <= 768;

  const getDropzoneText = () => (isMobile() || isSmallScreen()) ? 'Tap to upload a selfie' : 'Drag & drop a selfie or upload here';

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelfieUrl(reader.result);

      if (window.gtag) {
        window.gtag('event', 'upload', {
          event_category: 'User Interaction',
          event_label: 'Photo Uploaded'
        });
      }

      console.log("Uploading file:", file);
    };
    reader.readAsDataURL(file);
  }, []);

  function formatSeason(season) {
    return season.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

  useEffect(() => {
    if (selfieUrl && data) {
      // Track color season view event
      if (window.gtag) {
        window.gtag('event', 'view', {
          event_category: 'User Interaction',
          event_label: 'Color Season Displayed',
          value: data.color_season.toLowerCase()
        });
      }
      mixpanel.track("web_color_season_displayed", {
        "color_season": data.color_season
      });
    }
  }, [selfieUrl, data]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const analyzeColorSeasons = async () => {
    setLoading(true);
    const base64Image = selfieUrl.split(',')[1].replace(/\s/g, '');
    const url = new URL(`${process.env.REACT_APP_API_URL}/color_seasons/analyze`);

    const payload = { selfie: base64Image, user_id: 1 };

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      setData(data);
      setLoading(false);
      const colors = SEASON_HEX_CODES[data.color_season.toUpperCase()];
      setPresetColors(colors);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (selfieUrl) {
      mixpanel.track("web_color_season_selfie_uploaded");
      analyzeColorSeasons();
    }
  }, [selfieUrl]);

  return (
    <>
      <Helmet>
        <title>Your Color Closet</title>
        <meta property="og:title" content="CAPSULE | Your Color Closet" />
      </Helmet>
    
      <div className="season-container">
        {!selfieUrl && (
          <div className="split-container">
            <div className="left-container">
              <div className="season-header" style={{ textAlign: 'center', marginBottom: '10px' }}>
                <span style={{ display: 'block' }}>Your Color</span>
                <span style={{ display: 'block' }}>Closet</span>
              </div>
              <div className="season-sub-header">
                Upload your selfie, get your best colors, shop the perfect fits!
              </div>
              <div className="dropzone-container">
                <div 
                  {...getRootProps()} 
                  className="dropzone-component" 
                  style={{ 
                    border: '1px dashed #cccccc', 
                    padding: '10px', 
                    textAlign: 'center',
                    backgroundColor: isDragActive ? '#f0f0f0' : 'transparent'
                  }}
                >
                  <input {...getInputProps()} />
                  <div className="camera-icon-container">
                    <FontAwesomeIcon icon={faCamera} className="camera-icon" />
                  </div>
                  <div className="dropzone-text">
                    {getDropzoneText()}
                  </div>
                </div>
                <a 
                  href="/color-search" 
                  style={{ display: 'block', textAlign: 'center', margin: '20px 0', color: '#000', fontSize: '0.8em' }}
                >
                  Know your color season? <strong>Start shopping</strong>
                </a>
              </div>
              <div className="powered-by" style={{ textAlign: 'center' }}>
                <span>Powered by</span>
                <span><img className="logo" src={logo} alt="Logo" /></span>
                <div className="powered-by-about">
                  <div>the first visual fashion search engine:</div>
                  <div>shop your pics, link, and colors</div>
                </div>
              </div>
            </div>
            <div className="right-container">
              <img className="preview-image" src={preview} alt="Preview" />
            </div>
          </div>
        )}

        {selfieUrl && (
          <div className="results-container">
            <div className="selfie-column">
              <img className="selfie-img" src={selfieUrl} alt="Selfie" style={{ borderRadius: '10px' }} />
              {loading && (
                <div className="scanner-overlay">
                  <div className="scanner"></div>
                </div>
              )}
            </div>
            <div className="products-column">
              {data && (
                <>
                  <div className="color-results-column">
                    <div className="color-result-leadin">You are a...</div>
                    <div className="color-result-header">{formatSeason(data.color_season)}</div>
                    <div className="color-result-colors">
                      <div style={{ display: 'flex', gap: '10px' }}>
                        {SEASON_HEX_CODES[data.color_season.toUpperCase()].map((hex, index) => (
                          <div key={index} style={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                            backgroundColor: hex,
                            border: '.5px solid black'
                          }}></div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <button 
                    className="big-white-button" 
                    style={{ cursor: 'pointer' }} 
                    onClick={() => {
                      window.location.href = `/color-search?color_season=${data.color_season.toLowerCase()}`;
                    }}
                  >
                    Shop Your Colors
                  </button>
                  <div className="results-details">
                    <div className="results-header">A bit behind the magic...🪄 </div>
                    <div className="results-sub-header">Your color season is derived from:</div>
                    <div className="results-attribute">Hair Color: {data.hair_color}</div>
                    <div className="results-attribute">Eye Color: {data.eye_color}</div>
                    <div className="results-attribute">Hair Hexcode: <span style={{ background: data.hair_hexcode}}>{data.hair_hexcode}</span></div>
                    <div className="results-attribute">Eye Hexcode: <span style={{ background: data.eye_hexcode}}>{data.eye_hexcode}</span></div>
                    <div className="results-attribute">Skin Hexcode: <span style={{ background: data.skin_hexcode}}>{data.skin_hexcode}</span></div>
                    <div className="results-attribute">Skin Undertones: {data.skin_undertones}</div>
                    <div className="results-attribute">Skin Tone: {data.skin_tone}</div>
                    <div className="results-attribute">Skin Tint: {data.skin_tint}</div>
                    <div className="results-attribute">Skin Hue: {data.skin_muted_or_saturated}</div>
                  </div>
                  <div className="results-reset">
                    <div>Do you want to try another photo? 
                      <a 
                        style={{ cursor: 'pointer', textDecoration: 'none', marginLeft: '10px' }} 
                        onClick={() => setSelfieUrl('')}
                        href="#"
                      >
                        <strong>Reset</strong>
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Analysis;