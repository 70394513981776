import React, { useState, useEffect } from 'react';
import { HexColorPicker, HexColorInput } from "react-colorful";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeDropper, faCamera, faT, faLink, faCoffee, faFootballBall, faUser, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';
import mixpanel from "mixpanel-browser";

import './Search.css';

function Search() {
  const urlParams = new URLSearchParams(window.location.search);
  const [colorSeason, setColorSeason] = useState('')
  const [hexcode, setHexcode] = useState('');
  const [currentHexcode, setCurrentHexcode] = useState('');
  const [presetHexcodes, setPresetHexcodes] = useState([]);
  const [header, setHeader] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [results, setResults] = useState([]);
  const [backgroundGradient, setBackgroundGradient] = useState('');
  const [selectedColorSeasonName, setSelectedColorSeasonName] = useState('');
  const [selectedHexcode, setSelectedHexcode] = useState('');
  const [sortOrder, setSortOrder] = useState(''); // Add state for sort order
  const [sortedResults, setSortedResults] = useState([]);
  const [imageLoadingStatus, setImageLoadingStatus] = useState({});

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [showColorSeasonDropdown, setShowColorSeasonDropdown] = useState(false);

  const handleColorSelect = (hexcode, seasonName) => {
    setColorSeason(seasonName);
    setSelectedColorSeasonName(seasonName);
    setShowColorSeasonDropdown(false);

    const selectedSeason = colorSeasons.find(season => season.name === seasonName);
    setPresetHexcodes(selectedSeason.hexcodes);
    setHexcode(selectedSeason.hexcodes[0])
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  useEffect(() => {
    const sorted = [...results].sort((a, b) => {
      if (sortOrder === 'priceLowToHigh') {
        return parseFloat(a.price.replace('$', '')) - parseFloat(b.price.replace('$', ''));
      } else if (sortOrder === 'priceHighToLow') {
        return parseFloat(b.price.replace('$', '')) - parseFloat(a.price.replace('$', ''));
      }
      return 0;
    });
    setSortedResults(sorted);
  }, [results, sortOrder]);

  useEffect(() => {
    setBackgroundGradient(generateRandomGradient());
  }, []);

  const generateRandomGradient = () => {
    return `linear-gradient(${Math.random() * 360}deg, ${presetHexcodes.join(', ')})`;
  };

  const onDragEnd = () => {
    setHexcode(currentHexcode)
  }

  const colorSeasons = [
    { name: 'Light Spring', hexcodes: ["#ee6879", "#f5ad93", "#fff1a5", "#61cb65", "#c7f1b7", "#96d6d2", "#9fe7f5", "#96c8fc"] },
    { name: 'Bright Spring', hexcodes: ["#ed4847", "#f3a6a4", "#f5bedb", "#f7b640", "#fee83c", "#65d34e", "#5fd5c5", "#304098"] },
    { name: 'Warm Spring', hexcodes: ["#489a46", "#fd836a", "#bb4845", "#f7b640", "#ee6e47", "#e27f4d", "#84d46b", "#52b8ac"] },
    { name: 'Light Summer', hexcodes: ["#a36872", "#dbcef7", "#b5a1ce", "#f9dcf4", "#89e2b4", "#90b7fb", "#b5f4fb", "#4976bf"] },
    { name: 'Cool Summer', hexcodes: ["#7c4171", "#bd8ec4", "#973055", "#d13e4d", "#183779", "#91c4ea", "#65e4dd", "#4ba685"] },
    { name: 'Soft Summer', hexcodes: ["#a06b94", "#c5787f", "#e5699b", "#828fed", "#fff6b7", "#4fad83", "#6f94b6", "#9cccc7"] },
    { name: 'Soft Autumn', hexcodes: ["#a7552e", "#e8713a", "#ecd761", "#ddc1b6", "#df867f", "#326493", "#6cbebe", "#9579a7"] },
    { name: 'Warm Autumn', hexcodes: ["#9c2d35", "#c83a25", "#ee6030", "#e8c12e", "#844221", "#194234", "#636d0d", "#80b45d"] },
    { name: 'Deep Autumn', hexcodes: ["#be3b38", "#f49931", "#e4432d", "#fcd232", "#5f2d16", "#164a74", "#1a3e01", "#3e1a3a"] },
    { name: 'Clear Winter', hexcodes: ["#622b86", "#c53c5a", "#ec5095", "#e9e0fd", "#faf736", "#57b867", "#2b85c4", "#6befdc"] },
    { name: 'Cool Winter', hexcodes: ["#872b5a", "#aa9cbd", "#6f4786", "#252b5e", "#1a57be", "#e1f5fe", "#1f565e", "#3293cd"] },
    { name: 'Deep Winter', hexcodes: ["#5d2f79", "#973055", "#324261", "#cd4486", "#fff264", "#1a2682", "#c73d57", "#1a4850"] }
  ];

  const trendingForFallColors = ["#3B2921", "#E6C58B", "#1B275E", "#534839", "#B0281E", "#F4E0E7", "#666030", "#532631"];

  useEffect(() => {
    const urlColorSeason = urlParams.get('color_season') || "";
    const hash = window.location.hash.replace(/^#/, '');
    const hexcode = (urlParams.get('hexcode') || hash || '#008080');
    const formattedHexcode = hexcode.startsWith('#') ? hexcode : `#${hexcode}`;
    const selectedColorSeason = colorSeasons.find(colorSeason => 
      colorSeason.name.toLowerCase().replace(/ /g, '_') === urlColorSeason.toLowerCase()
    );

    if (selectedColorSeason) {
      setColorSeason(selectedColorSeason.name);
      setPresetHexcodes(selectedColorSeason.hexcodes);
      setHexcode(selectedColorSeason.hexcodes[0]);
    } else {
      setHexcode(formattedHexcode);
      console.log(formattedHexcode)
    }

  }, []);

  useEffect(() => {
    const selectedColorSeason = colorSeasons.find(cs => cs.name.toLowerCase().replace(/ /g, '_') === colorSeason.toLowerCase());

    if (selectedColorSeason) {
      setColorSeason(selectedColorSeason.name);
      setHexcode(selectedColorSeason.colors[0]);
      setPresetHexcodes(selectedColorSeason.colors);

    }
  }, [colorSeason]);

  const updateHexcodeInURL = (newHexcode) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('hexcode', newHexcode.replace(/^#/, ''));
    window.history.replaceState(null, '', `?${urlParams.toString()}`);
  };

  useEffect(() => {
    updateHexcodeInURL(hexcode);
  }, [hexcode]);

  useEffect(() => {
    if (hexcode || searchQuery) {
      if (window.gtag) {
        window.gtag('event', 'search', {
          event_category: 'engagement',
          event_label: searchQuery || hexcode,
        });
      }
      mixpanel.track('web_color_searched', {
        hexcode: hexcode,
      });
      const params = new URLSearchParams({ hexcode: hexcode, query: searchQuery, is_website: true });
      const apiUrl = new URL(`${process.env.REACT_APP_API_URL}/color_search`);
      const token = 'capsule-shopping-1234';
     
      fetch(`${apiUrl}?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => setResults(data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [hexcode, searchQuery]);

  useEffect(() => {
    if (results) {
      results.forEach((item, index) => {
        if (item.image) {
          console.log('print image')
          console.log(results.length)
          const img = new Image();
          const separator = item.image.includes('?') ? '&' : '?';
          img.src = item.image.includes('cdn.shopify') ? `${item.image}${separator}height=200` : item.image;
          img.onload = () => {
            setImageLoadingStatus(prevStatus => ({
              ...prevStatus,
              [index]: 'loaded' // Image loaded successfully
            }));
          };
          img.onerror = () => {
            setImageLoadingStatus(prevStatus => ({
              ...prevStatus,
              [index]: 'error' // Image failed to load
            }));
          };
          setImageLoadingStatus(prevStatus => ({
            ...prevStatus,
            [index]: 'loading' // Image is currently loading
          }));
        }
      });
    }
  }, [results]);

  const clearColorSeason = () => {
    setPresetHexcodes([])
    // setHexcode('#008080');
    setColorSeason('');
    setSelectedColorSeasonName('');
  }

  const handleColorSeasonChange = (event) => {
    const selectedColorSeason = colorSeasons.find(colorSeason => 
        colorSeason.name.toLowerCase().replace(/ /g, '_') === event.target.value
    );

    if (selectedColorSeason) {
        setColorSeason(selectedColorSeason.name);
        setPresetHexcodes(selectedColorSeason.colors);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('color_season', selectedColorSeason.name.toLowerCase().replace(/ /g, '_'));
        window.history.replaceState(null, '', `?${urlParams.toString()}`);
        console.log(colorSeason)
    }
  };

  const handleTrendingForFallSelect = () => {
    setColorSeason('Trending for Fall');
    setPresetHexcodes(trendingForFallColors);
    setHexcode(trendingForFallColors[0]);
    setSelectedColorSeasonName('Trending for Fall');
  };

  return (
    <>
    <Helmet>
      <title>Color Search</title>
      <meta property="og:title" content="CAPSULE | Color Search" />
    </Helmet>
    <div className="search-container">      
      <div className="input-button-container">
        <div className="color-search search">
            <div className="center-container">
              <div 
                className="color-circle" 
                style={{ '--hexcode-color': hexcode === '#008080' ? '#FFFFFF' : hexcode }} 

                onClick={() => setShowColorPicker(!showColorPicker)}
              >
                <FontAwesomeIcon icon={faEyeDropper} className="color-picker-icon" />
              </div>
              {showColorPicker && (
                <section className="custom-layout example" >
                  <div onClick={() => setShowColorSeasonDropdown(false)}>
                    <HexColorPicker color={hexcode} onChange={setCurrentHexcode} onMouseUp={onDragEnd} onTouchEnd={onDragEnd} className="custom-sketch-picker" />
                    <HexColorInput color={hexcode} onChange={setCurrentHexcode} onMouseUp={onDragEnd} onTouchEnd={onDragEnd} className="hexcode-picker custom-sketch-picker"/>
                  </div>
                </section>
              )}
              <p className="small-text">Search by Color</p>
            </div>
          </div>
          <div className="color-seasons-container">
            {presetHexcodes.length > 0 && (
              <>
                <div className="centered-header">
                  {colorSeason && (
                    <>
                      <div className="color-season-display">
                        {colorSeason}
                      </div>
                      <div className="align-right-container">
                      <button 
                        className="clear-preset-colors" 
                        onClick={() => clearColorSeason()}
                      >
                          <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  </>
                  )}
                </div>
                <div className="preset-colors grid">
                {presetHexcodes.slice(0, 8).map((presetHexcode, index) => (
                  <div 
                    key={index} 
                    className="preset-color-circle" 
                    style={{ '--preset-color': presetHexcode,  border: selectedHexcode === presetHexcode ? '2px solid black' : 'none'  }} 
                    onClick={() => {
                      setHexcode(presetHexcode);
                      setSelectedHexcode(presetHexcode); // Update the selectedHexcode state
                    }}
                  />
                  
                ))}
  
              </div>
              </>
          )}
          </div>
 
      {!colorSeason && !selectedColorSeasonName && (
        <div className="deactivated-search">
          <div className="image-search search" onClick={() => setShowColorSeasonDropdown(!showColorSeasonDropdown)}>
            <div className="center-container">
              <div className="center-container color-circle" style={{ '--hexcode-color': '#FFF' }} >
                <FontAwesomeIcon icon={faUser} className="image-search-icon" />
              </div>
              <p className="small-text">Color Season</p>
            </div>
          </div>

          {showColorSeasonDropdown && (
            <div className="color-season-dropdown"
            onClick={() => setShowColorPicker(false)}>
              {colorSeasons.map((season, index) => (
                <div key={index} className="color-season-section">
                  <div className="color-season-name">{season.name}</div>
                  <div className="color-grid">
                    {season.hexcodes.map((color, idx) => (
                      <div 
                        key={idx} 
                        className="color-square" 
                        style={{ backgroundColor: color }} 
                        onClick={() => handleColorSelect(color, season.name)}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="text-search search" onClick={handleTrendingForFallSelect}>
            <div className="center-container">
              <div className="color-circle" style={{ '--hexcode-color': '#FFF' }} >
                <FontAwesomeIcon icon={faCoffee} className="image-search-icon" />
              </div>
              <p className="small-text">Trending for Fall</p>
            </div>
          </div>
          <div className="text-search search">
          {/* <div className="coming soon center-container">
            <div className="color-circle color-circle-soon" style={{ '--hexcode-color': '#FFF' }} >
              <FontAwesomeIcon icon={faFootballBall} className="image-search-icon greyed-out" />
              <span className="tooltip">Coming Soon</span>
            </div>
            <p className="small-text">School Spirit</p>
          </div> */}
        </div>

          </div>
        )} 

      </div>
      <div className="input-group"></div>
      {showTooltip && (
        <div className="tooltip-container">
          <span>Psst… We scrape 25K sites every night and are constantly expanding. <br />
          Who would you like us to add next? </span>
          <a href="https://forms.gle/Y82mBXYoqEZwQjBM6" target="_blank" rel="noopener noreferrer">
            <b><u>Tell us here</u></b>
          </a>
          <button onClick={() => setShowTooltip(false)} className="close-tooltip">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    <div className="sort-dropdown align-right">
      <label htmlFor="sortOrder">Sort By: </label>
      <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
        <option value="">Select</option>
        <option value="priceLowToHigh">Price (Low to High)</option>
        <option value="priceHighToLow">Price (High to Low)</option>
      </select>
    </div>
    <div className="results-container">
        <div className="results-grid">
          {Array.isArray(sortedResults) && sortedResults.map((item, index) => (
            <div key={index} className="result-item">
              <a 
                href={item.url} 
                className="clickable-link" 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={() => {
                  mixpanel.track('web_link_clicked', {
                    url: item.url,
                    retailer: item.retailer,
                    title: item.title,
                    price: item.price,
                    position: index + 1
                  });
                }}
              >
               {imageLoadingStatus[index] === 'loaded' ? (
                <>
                  <img src={item.image} alt={`Item ${index}`} className="result-image" />
                  <p className="no-margin retailer">{item.retailer}</p>
                  <p className="no-margin truncate-title title">{item.title}</p>
                  <p className="no-margin price">{item.price}</p>
                </>
              ) : (
                <div className="pulse-placeholder">
                  <div className="pulse"></div>
                  <div className="pulse-line"></div>
                  <div className="pulse-line"></div>
                  <div className="pulse-line"></div>
                </div>
              )}
            </a>
          </div>
          ))}
        </div>
      </div>  
    </div>
    </>
  );
}

export default Search;